import { useTranslation } from "next-i18next"
import Typography from "src/views/components/typography"

const shareOptions = [
  {
    id: 1,
    label: "COPY",
    icon: (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.3238 7H13.2476V9H17.3238C19.0052 9 20.3809 10.35 20.3809 12C20.3809 13.65 19.0052 15 17.3238 15H13.2476V17H17.3238C20.1364 17 22.419 14.76 22.419 12C22.419 9.24 20.1364 7 17.3238 7ZM11.2095 15H7.13332C5.4519 15 4.07618 13.65 4.07618 12C4.07618 10.35 5.4519 9 7.13332 9H11.2095V7H7.13332C4.32075 7 2.03809 9.24 2.03809 12C2.03809 14.76 4.32075 17 7.13332 17H11.2095V15ZM8.15237 11H16.3048V13H8.15237V11Z"
          fill="#909090"
        />
      </svg>
    )
  },
  {
    id: 2,
    label: "LINKEDIN_SHARE",
    icon: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21 21V14.4075C21 11.1675 20.3025 8.6925 16.5225 8.6925C14.7 8.6925 13.485 9.6825 12.99 10.6275H12.945V8.985H9.3675V21H13.1025V15.0375C13.1025 13.4625 13.395 11.955 15.33 11.955C17.2425 11.955 17.265 13.7325 17.265 15.1275V20.9775H21V21ZM3.2925 8.985H7.0275V21H3.2925V8.985ZM5.16 3C3.9675 3 3 3.9675 3 5.16C3 6.3525 3.9675 7.3425 5.16 7.3425C6.3525 7.3425 7.32 6.3525 7.32 5.16C7.32 3.9675 6.3525 3 5.16 3Z"
          fill="#909090"
        />
      </svg>
    )
  },
  {
    id: 3,
    label: "FACEBOOK_SHARE",
    icon: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.69604 13.248H6.32004C5.93604 13.248 5.81604 13.104 5.81604 12.744V9.83995C5.81604 9.45595 5.96004 9.33595 6.32004 9.33595H8.69604V7.22395C8.69604 6.26395 8.86404 5.35195 9.34404 4.51195C9.84804 3.64795 10.568 3.07195 11.48 2.73595C12.08 2.51995 12.68 2.42395 13.328 2.42395H15.68C16.016 2.42395 16.16 2.56795 16.16 2.90395V5.63995C16.16 5.97595 16.016 6.11995 15.68 6.11995C15.032 6.11995 14.384 6.11995 13.736 6.14395C13.088 6.14395 12.752 6.45595 12.752 7.12795C12.728 7.84795 12.752 8.54395 12.752 9.28795H15.536C15.92 9.28795 16.064 9.43195 16.064 9.81595V12.72C16.064 13.104 15.944 13.224 15.536 13.224H12.752V21.048C12.752 21.456 12.632 21.6 12.2 21.6H9.20004C8.84004 21.6 8.69604 21.456 8.69604 21.096V13.248Z"
          fill="#909090"
        />
      </svg>
    )
  }
]

interface Props {
  vacancyId: number
  vacancyTitle: string
  onClose: () => void
  top: string
  right: string
}

const VacancyShare = ({ vacancyId, vacancyTitle, onClose, top, right }: Props) => {
  const { t } = useTranslation("common")

  const copyLinkHandle = (vacancyLink: string) => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(vacancyLink).catch(err => {
        console.log(err)
      })
    } else {
      const textArea = document.createElement("textarea")
      textArea.value = vacancyLink
      textArea.style.position = "fixed"
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.opacity = "0"
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand("copy")
      } catch (err) {
        console.log(err)
      }
      document.body.removeChild(textArea)
    }
  }
  const handleClick = (id: number) => {
    const vacancyLink = `${window.location.origin}/vacancy/${vacancyId}`

    if (id === 1) {
      copyLinkHandle(vacancyLink)
    }

    if (id === 2) {
      const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(vacancyLink)}&title=${encodeURIComponent(vacancyTitle)}&summary=Check%20out%20this%20vacancy&source=myjobs.ge`
      window.open(linkedInShareUrl, "_blank")
    }

    if (id === 3) {
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(vacancyLink)}&quote=${encodeURIComponent(vacancyTitle)}`
      window.open(facebookShareUrl, "_blank")
    }

    onClose()
  }

  return (
    <>
      <div
        className={`absolute right-${right} z-0 top-${top} mt-10 h-[164px] w-[277px] rounded-lg bg-white p-2.5 shadow-lg`}
      >
        <ul className="flex flex-col items-start">
          {shareOptions.map(option => (
            <li
              key={option.id}
              className="w-full cursor-pointer rounded-lg hover:bg-neutral-10"
              onClick={() => handleClick(option.id)}
            >
              <div className="flex items-center gap-2 px-4 py-3">
                {option.icon}
                <Typography type="text" className="text-secondary-50">
                  {t(option.label)}
                </Typography>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default VacancyShare
