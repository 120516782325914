import { FC, useContext } from "react"
import Button from "src/views/components/common/Button/Button"
import { ResumeApplyContext } from "src/context/ResumeApplyProvider"
import useVacancy from "src/server/queryHooks/useVacancy"
import { useTranslation } from "next-i18next"

interface Props {
  vacancy_id: number
  size?: any
  applyUrl?: string | null
  styles?: any
}

const ApplyButton: FC<Props> = ({ vacancy_id, size = "big", applyUrl, styles }) => {
  const { t } = useTranslation("common")
  const { setVacancyId } = useContext(ResumeApplyContext)
  const { vacancySavedAppliedStatus } = useVacancy()
  const isApplied = vacancySavedAppliedStatus?.data?.applied[vacancy_id]

  const handleClick = () => {
    if (applyUrl) {
      window.open(applyUrl, "_blank")
    } else {
      setVacancyId(vacancy_id)
    }
  }

  return (
    <Button
      intent="primary"
      size={size}
      text={isApplied ? t("SENT") : t("SEND")}
      disabled={isApplied}
      rounded="full"
      weight="bold"
      textClassName="text-sm"
      onClick={handleClick}
      className={styles ? "w-full" : ""}
    />
  )
}

export default ApplyButton
